import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/psdev/Documents/gitdev/workspace/working/myproject/hds25-gatby4-anndream-website-2022/site/src/components/layout.js";
import { Link } from 'hds-react';
import LeadParagraph from '../../components/LeadParagraph';
import InternalLink from '../../components/InternalLink';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "roadmap-เทส",
      "style": {
        "position": "relative"
      }
    }}>{`Roadmap เทส`}<a parentName="h1" {...{
        "href": "#roadmap-%E0%B9%80%E0%B8%97%E0%B8%AA",
        "aria-label": "roadmap เทส permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h1>
    <LeadParagraph mdxType="LeadParagraph">
  This is the roadmap of the Helsinki Design System. The roadmap is subject to change according to feedback and feature
  requests we receive. This list is regularly updated.
    </LeadParagraph>
    <ul>
      <li parentName="ul"><strong parentName="li">{`HDS Alpha release 26.5.2020`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`HDS Beta release 29.10.2020`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`HDS 1.0.0 release 3.5.2021`}</strong></li>
    </ul>
    <p>{`For previously released features, see changelogs in the `}<InternalLink href="/about/what-is-new" mdxType="InternalLink">{`What is new section.`}</InternalLink></p>
    <h2 {...{
      "id": "2022",
      "style": {
        "position": "relative"
      }
    }}>{`2022`}<a parentName="h2" {...{
        "href": "#2022",
        "aria-label": "2022 permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "q1-2022",
      "style": {
        "position": "relative"
      }
    }}>{`Q1 2022`}<a parentName="h3" {...{
        "href": "#q1-2022",
        "aria-label": "q1 2022 permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul"><del parentName="li">{`Pattern: Multi-step forms`}</del></li>
      <li parentName="ul"><del parentName="li">{`Component: Form stepper`}</del></li>
    </ul>
    <h3 {...{
      "id": "q2-2022",
      "style": {
        "position": "relative"
      }
    }}>{`Q2 2022`}<a parentName="h3" {...{
        "href": "#q2-2022",
        "aria-label": "q2 2022 permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul"><del parentName="li">{`Update: Typography`}</del></li>
      <li parentName="ul"><del parentName="li"><strong parentName="del">{`HDS 2.0.0 Release`}</strong></del></li>
    </ul>
    <h3 {...{
      "id": "q3q4-2022",
      "style": {
        "position": "relative"
      }
    }}>{`Q3/Q4 2022`}<a parentName="h3" {...{
        "href": "#q3q4-2022",
        "aria-label": "q3q4 2022 permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul"><del parentName="li">{`Component: Pagination`}</del></li>
      <li parentName="ul"><del parentName="li">{`Pattern: Cookie consent`}</del></li>
      <li parentName="ul"><del parentName="li">{`Component: Cookie consent`}</del></li>
      <li parentName="ul">{`Update: Navigation improvements`}</li>
      <li parentName="ul">{`Update: Footer improvements`}</li>
      <li parentName="ul">{`Component: Breadcrumbs`}</li>
      <li parentName="ul">{`Component: File download`}</li>
      <li parentName="ul">{`Component: Hero`}</li>
      <li parentName="ul">{`Component: Maps`}</li>
      <li parentName="ul">{`Component: Slider`}</li>
      <li parentName="ul">{`Component: Carousel`}</li>
      <li parentName="ul">{`Component: Article highlights and quotes`}</li>
      <li parentName="ul">{`Pattern: Image usage instructions`}</li>
      <li parentName="ul">{`Pattern: Tone of voice instructions`}</li>
      <li parentName="ul">{`Pattern: Full page templates`}</li>
      <li parentName="ul">{`Update: Card examples and variations`}</li>
      <li parentName="ul">{`Update: Search improvements`}</li>
      <li parentName="ul">{`Update: Documentation improvement`}</li>
    </ul>
    <hr></hr>
    <h2 {...{
      "id": "2021",
      "style": {
        "position": "relative"
      }
    }}>{`2021`}<a parentName="h2" {...{
        "href": "#2021",
        "aria-label": "2021 permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "q1-2021",
      "style": {
        "position": "relative"
      }
    }}>{`Q1 2021`}<a parentName="h3" {...{
        "href": "#q1-2021",
        "aria-label": "q1 2021 permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul"><del parentName="li">{`Component: Datepicker and date field`}</del></li>
      <li parentName="ul"><del parentName="li">{`Component: Time input field`}</del></li>
      <li parentName="ul"><del parentName="li">{`Component: Number input field`}</del></li>
      <li parentName="ul"><del parentName="li">{`Icons: Next set of icons`}</del></li>
      <li parentName="ul"><del parentName="li">{`Component: Dialog`}</del></li>
      <li parentName="ul"><del parentName="li">{`Component: Phone number input field`}</del></li>
      <li parentName="ul"><strong parentName="li">{`HDS 1.0.0 Release`}</strong></li>
    </ul>
    <h3 {...{
      "id": "q2-2021",
      "style": {
        "position": "relative"
      }
    }}>{`Q2 2021`}<a parentName="h3" {...{
        "href": "#q2-2021",
        "aria-label": "q2 2021 permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul"><del parentName="li">{`Component: Password input field`}</del></li>
      <li parentName="ul"><del parentName="li">{`Component: Fieldset`}</del></li>
      <li parentName="ul"><del parentName="li">{`Component: Toggle Button`}</del></li>
      <li parentName="ul"><del parentName="li">{`Component: Link`}</del></li>
    </ul>
    <h3 {...{
      "id": "q3-2021",
      "style": {
        "position": "relative"
      }
    }}>{`Q3 2021`}<a parentName="h3" {...{
        "href": "#q3-2021",
        "aria-label": "q3 2021 permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul"><del parentName="li">{`Component: Side navigation`}</del></li>
      <li parentName="ul"><del parentName="li">{`Component: Link box`}</del></li>
      <li parentName="ul"><del parentName="li">{`Component: File input`}</del></li>
    </ul>
    <h3 {...{
      "id": "q4-2021",
      "style": {
        "position": "relative"
      }
    }}>{`Q4 2021`}<a parentName="h3" {...{
        "href": "#q4-2021",
        "aria-label": "q4 2021 permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul"><del parentName="li">{`Component: Basic tables`}</del></li>
      <li parentName="ul"><del parentName="li">{`Icons: Next set of icons`}</del></li>
      <li parentName="ul"><del parentName="li">{`Update: Koros improvements`}</del></li>
      <li parentName="ul"><del parentName="li">{`Update: Smaller improvements and fixes to multiple components`}</del></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      